// NOTE FOR AI ASSISTANTS!!: 
// Only put non-color specific styles in here. 
// Color specific styles should go in ./themes/dark-theme.css

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap')

html, body, .container
  height: 100%
  margin: 0
  padding: 0
  display: flex
  flex-direction: column
  overflow-x: hidden
  font-family: 'Source Code Pro', monospace

p
  font-family: 'Rotobo', sans-serif

main
  margin-top: 8vh
  margin-bottom: 8vh

.social-icons
  display: flex
  justify-content: center
  align-items: center
  margin-top: 0

.icon
  margin: 0 0.5rem
  font-size: 1.5rem
  transition: color 0.5s ease, border-color 0.15s ease
  display: inline-block
  width: 1.5rem

  svg
    width: 100%
    height: 100%

.grid
  display: flex
  flex-direction: row
  justify-content: space-between
  margin: 3rem 0
  overflow-x: auto

.card
  box-sizing: border-box
  padding-left: 1rem
  border-radius: 0.5rem
  min-height: 20vh
  max-height: 30vh
  min-width: 30vw
  display: flex
  justify-content: center
  align-items: center

.card-small
  box-sizing: border-box
  padding-left: 1rem
  margin: 1rem
  border-radius: 0.5rem
  min-height: 10vh
  max-height: 20vh
  min-width: 15vw
  display: flex
  justify-content: center
  align-items: center
  opacity: 0.8
  #cta
    opacity: 0
    margin-left: 1rem

.card-small:hover
  opacity: 1
  #cta
    opacity: 1

.header
  position: relative
  width: 100%
  padding: 0.4rem
  display: flex
  justify-content: space-between
  align-items: center
  background: var(--card-background-color)

.header .title
  margin-left: 1rem

.header .social-icons
  margin-right: 1rem

.description
  max-width: 100vw
  font-size: 1.2rem
  padding: 1rem
  box-sizing: border-box

.blog
  margin-left: 23vw
  margin-right: 23vw
  margin-top: 10vh
  margin-bottom: 10vh

.video-container
  position: relative
  overflow: hidden
  width: 100%
  padding-top: 56.25%

.video-container iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.blog img
  width: 40vw
  height: auto
  display: block
  margin-left: auto
  margin-right: auto
  margin-top: 20px

pre
  border-width: 1px
  border-radius: 0.5rem

.header-container
  display: flex
  align-items: center

.header-image
  width: 50px
  height: 50px
  border-radius: 50%
  overflow: hidden

.cursor-pointer
  cursor: pointer
.bio-section
  display: block

.image-container
  position: relative

.bio-image
  float: right
  margin: 0 0 1em 1em
  clear: both
  width: 30vw
  height: auto

.image-container
  width: 100%
  height: 30vh
  overflow: hidden

.post-image
  width: 100%
  height: 100%
  object-fit: cover

footer
  position: absolute
  bottom: 0
  width: 100%
  height: 60px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  z-index: 100

  h3
    margin: 0
    margin-bottom: -.5rem
    padding: 0
    display: block
    font-size: 1rem
  p
    display: block
    font-size: 0.8rem
    margin-bottom: -.5rem

main
  position: relative
  overflow: auto
  z-index: 1

  .main-content
    position: relative
    padding: 0 0.5rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 100%
    overflow: auto

@media (max-width: 1200px)
  .grid
    flex-direction: column
    align-items: center
    overflow-x: hidden
    min-width: 90vw

  .card-large
    opacity: 0.8
    margin-bottom: 2rem
    padding-bottom: 2rem
    flex: 0 0 calc(50% - 1rem)
    max-width: 100%
    min-width: 100%
    min-height: 35vh
    background: var(--card-background-color)
    border-radius: 0.5rem
    position: relative // Add this line to make the position of #cta relative to .card-large
    #cta
      opacity: 0
      position: absolute
      top: 1vh
      right: 2vw
    p
      font-size: 1.2rem
      margin-left: 2.5rem

    h2
      margin-left: 2.5rem

    img
      width: auto
      max-height: 25vh
      height: auto
      display: block
      margin-left: auto
      margin-right: auto
      margin-top: 20px


@media (max-width: 800px) // mobile specific styles
  main
    margin-left: 3vw
    margin-right: 3vw

  #quora-link, #stackoverflow-link
    display: none

  .grid
    flex-direction: column
    align-items: center
    overflow-x: hidden
    min-width: 90vw

  .blog
    margin-left: 5vw
    margin-right: 5vw

  .blog img
    width: 95vw

  .card
    width: 100%
    margin-bottom: 1rem
    padding: 0.5rem
    padding-left: 1.5rem
    height: 10vh

  .card-small
    width: 100%
    margin-bottom: 1rem
    padding: 0.5rem
    padding-left: 1.5rem
    height: 10vh

  .description
    width: 100vw
    font-size: 0.7rem
    box-sizing: border-box
    margin-top: 2vh

  .header .title
    font-size: 1rem

  .header-container
    margin-left: 0.5rem

  .icon
    font-size: 1.2rem
    height: 1rem

  #about-canvas
    margin-left: auto
    margin-right: auto
    width: auto
    display: block
    height: 40vh

  .image-container
    width: 100%
    max-height: 30vh
    overflow: hidden

  .post-image
    width: 90%
    height: 100%
    object-fit: cover

  
  .card-large:hover
    opacity: 1
    #cta
      opacity: 1

@media (min-width: 800px) // non-mobile specific styles
  h2
    font-size: 1.6rem
  p
    font-size: 1.2rem

  .grid
    min-width: 40vw
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .card
    margin-left: 0.5rem
    margin-bottom: 1rem
    flex: 0 0 calc(33.33% - 1rem)
    max-width: calc(33.33% - 1rem)
    #cta
      opacity: 0

  .card-large
    opacity: 0.8
    margin-bottom: 2rem
    padding-bottom: 2rem
    flex: 0 0 calc(50% - 1rem)
    max-width: calc(50% - 1rem)
    min-width: calc(50% - 1rem)
    min-height: 35vh
    background: var(--card-background-color)
    border-radius: 0.5rem
    position: relative // Add this line to make the position of #cta relative to .card-large
    #cta
      opacity: 0
      position: absolute
      top: 1vh
      right: 2vw
    p
      font-size: 1.2rem
      margin-left: 2.5rem

    h2
      margin-left: 2.5rem

    img
      width: auto
      max-height: 25vh
      height: auto
      display: block
      margin-left: auto
      margin-right: auto
      margin-top: 20px

  .card-large:hover
    opacity: 1
    #cta
      opacity: 1

  .description
    margin-top: 25vh

  .header-container
    margin-left: 2rem

  .icon
    font-size: 1.2rem
    height: 2rem

  canvas
    float: right
    margin: 0 0 1em 1em
    clear: both
    width: 30vw
    height: auto

  main
    max-width: 100vw
    margin-left: "auto"
    margin-right: "auto"
    padding-left: 15vw
    padding-right: 15vw

@media (min-width: 2200px)
  .description
    font-size: 1.6rem
    font-family: 'Roboto', monospace

  p
    font-size: 1.3rem

  .icon
    margin: 0 .5rem
    width: 3rem
