/* Dark theme styles */
:root {
  --background-color: #121212; /* Dark background */
  --text-color: #e0e0e0; /* Light text */
  --link-color: #ffffff; /* Light purple for links */
  --link-hover-color: #ffcf68; /* Darker purple for link hover state */
  --border-color: rgb(235, 226, 226); /* Dark border for elements */
  --card-background-color: #1e1e1e; /* Slightly lighter background for cards */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: var(--text-color);
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  color: var(--link-hover-color);
}

.card,
.card-small,
.card-large {
  background-color: var(--card-background-color);
  transition: background-color 0.3s ease;
}

.card:hover,
.card-small:hover,
.card-large:hover {
  background-color: #2a2a2a; /* Lightened background color for hover state */
}

.header {
  background-color: var(--card-background-color);
  box-shadow: 0 2px 4px 0 rgba(30, 30, 30, 0.7);
}

footer {
  background-color: var(--card-background-color);
}

.icon {
  color: #F8F8F8; 
  svg {
    fill: currentColor;
  }
}
